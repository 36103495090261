import { Link, useParams } from "react-router-dom";
import { FetchPatientInfo } from "./fetchPatientInfo";
import { Wrapper } from "../mainComponent/Wrapper";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { useRecoilState } from "recoil";
import { setHelperData } from "../recoil/atom/setHelperData";
import { MainNav } from "../mainComponent/mainNav";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import GetDependent from "./getDependent";

export default function GetLoginPatientProfile() {
    const { patientId } = useParams()
    const [doctorId] = useRecoilState(setDoctorId);
    const [helpersData] = useRecoilState(setHelperData)

    return (
        <>
            <Wrapper>
                <MainNav>
                    <ul className="clearfix">
                        <li>
                            <Link to={`/appointments/${doctorId}`}>
                                <i className="arrow_back backArrow" title="back button"></i>
                            </Link>
                        </li>
                        <li className='float-none' style={{ fontSize: 'inherit' }}>Walkin Patient</li>
                    </ul>
                </MainNav>
                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width_84'>
                        <div className=" common_box ">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="white-box  patientDetails">
                                        <FetchPatientInfo doctorId={doctorId} patientId={patientId} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="white-box  patientDetails">
                                        <GetDependent doctorId={doctorId} patientId={patientId} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    )
}