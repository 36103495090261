import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AddDoctorClinicInfo } from '../Clinic/Partial/AddDoctorClinicInfo';
import UserLinks from '../../Dashboard-card/partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from 'recoil';
import { MainNav } from '../../../mainComponent/mainNav';
import { Wrapper } from '../../../mainComponent/Wrapper';

function AllClinicList() {
    const { doctorId, id } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <Link to={`/newdoctor/${doctorId}`}>
                        <i className="arrow_back backArrow" title="back button"></i>
                    </Link>
                    <li className='float-none ml-2' style={{ fontSize: 'inherit' }} >Clinic List</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <AddDoctorClinicInfo doctorId={doctorId} newDoctorId={id} />
                    </div>
                </div>
            </div>
        </Wrapper>


    )
}
export { AllClinicList }