import axios from 'axios';
import { API } from '../config';

export default function MedicineApi() {
    const insertMedicine = async (doctorId, bodydata) => {
        try {
            const result = await axios.post(`${API}/addmedicine/${doctorId}`, bodydata)
            return result.data;
        }
        catch (err) {
            return err
        }
    }
    const fetchMedicine = async (doctorId, data) => {
        try {
            const result = await axios.post(`${API}/getmedicines/${doctorId}`, data)
            return result.data;
        }
        catch (err) {
            return err
        }
    }
    const deleteDocotrMedicine = async (medicineId) => {
        try {
            const result = await axios.delete(`${API}/deletedoctormedicine/${medicineId}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    return {
        insertMedicine,
        fetchMedicine,
        deleteDocotrMedicine
    }
}