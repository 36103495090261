import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../mainComponent/mainInput";
import AuthApi from "../../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import { Button } from "react-bootstrap";
import UserLinks from "../../Dashboard-card/partial/uselinks";
import { Wrapper } from "../../../mainComponent/Wrapper";
import { MainNav } from "../../../mainComponent/mainNav";
import { setHelperData } from "../../../recoil/atom/setHelperData";

export default function NewDoctorMpinForm() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [error, setError] = useState(false);
    const [password, setPassword] = useState('');
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [doctorId] = useRecoilState(setDoctorId)

    const { signIn } = AuthApi();
    const navigate = useNavigate()

    const handleSignInSubmit = (e) => {
        e.preventDefault();
        if (mobile.length < 10) {
            setError('Mobile number must be 10 digits');
            return;
        }
        if (!password) {
            setError('Password cannot be empty');
            return;
        }
        signIn({ mobile, password })
            .then(response => {
                if (response.data.status) {
                    setError(response.status.error || 'You have entered an invalid credentails');
                } else {
                    navigate(`/newdoctor/${doctorId}`);
                }
            })
            .catch(err => {
                console.error('Sign-in error:', err);
                setError('You have entered an invalid credentails');
            });
    };



    const createAccount = () => {
        navigate(`/newdoctor/${doctorId}/creatempin`)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/newdoctor/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Login</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <div className="container margin_60_35">
                        <div id="login-2">
                            <h1>login as a Doctor</h1>
                            <form >
                                <div className=" clearfix">
                                    <div className="last">
                                        <div className="row ">
                                            <lable className='mb-2 fontSize'>Mobile Number</lable>
                                            <div className="col-md-12 mb-2">
                                                <input
                                                    name="mobile"
                                                    value={mobile.mobile}
                                                    className="form-control"
                                                    maxLength={10}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    placeholder="Phone Number (+XX)" />
                                            </div>

                                            <lable className='mb-2 mt-2 fontSize'>Enter MPIN</lable>
                                            <div className="col-md-12 ">
                                                <MainInput
                                                    className="form-control"
                                                    type="password"
                                                    name="password"
                                                    maxLength={6}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Password"
                                                    required>
                                                </MainInput>
                                            </div>
                                            <div className="row">
                                                <div className="mr-2">
                                                    <MainButtonInput onClick={handleSignInSubmit}>Login</MainButtonInput>
                                                </div>
                                                <div className="">
                                                    <Button
                                                        type="submit"
                                                        onClick={() => createAccount()}
                                                        variant="default"
                                                        className='lightbuttonColor  mr-3 btn_sub'>
                                                        Create Account
                                                    </Button>
                                                </div>

                                                <div className="mt-2" align='right'>
                                                    <Link to='/forgetmpin'>Forget MPIN</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="validation mt-2">
                                        {error}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </Wrapper >
    )
}