import React from 'react';
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import GetMedicinePriscription from './GetMedicinePrescription';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import AddMedicineModalBox from './AddMedicineModalBox';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function MedicinePrescription(props) {
    const { onChange, reportId, doctorId } = props;
    const [showData, setShowData] = useState(false);
    const isMobile = useMediaQuery('(max-width:  768px)')
    const [open, setOpen] = useState(false);

    const handleDataClose = () => {
        setShowData(false)
        setOpen(false);
    }

    const handleDataShow = (id) => {
        setShowData(true)
        setOpen(true)
    }

    const onDataFormSubmit = () => {
        handleDataClose()
    };

    return (
        <div>
            <div align='right'>
                <MainButtonInput
                    className='align-left'
                    onClick={handleDataShow}>
                    ADD MEDICINES
                </MainButtonInput>
            </div>
            <div>
                <GetMedicinePriscription doctorId={doctorId} reportId={reportId} />
                {isMobile ? (
                    <Drawer anchor="top" open={open} onClose={handleDataClose}>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">Add Medicine</Typography>
                            <IconButton onClick={handleDataClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-4'>
                            <AddMedicineModalBox
                                reportId={reportId}
                                doctorId={doctorId}
                                onSubmit={onDataFormSubmit}
                                onChange={onChange}
                            />
                        </div>
                    </Drawer>
                ) : (
                    <Modal show={showData} onHide={handleDataClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Medicines</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddMedicineModalBox
                                reportId={reportId}
                                doctorId={doctorId}
                                onSubmit={onDataFormSubmit}
                                onChange={onChange}
                            />
                        </Modal.Body>
                    </Modal>
                )}
            </div>
            <div className="row float-right">
                <div className="text-left ml-2 mt-2">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>

            <div className="row float-right">
                <Toaster />
            </div>
        </div>
    )
}