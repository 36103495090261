import React, { useEffect, useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import ReportApi from '../../../services/ReportApi';
import { useRecoilState } from 'recoil';
import { setMedicineData } from '../../../recoil/atom/setMedicineData';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';

const GetMedicinePriscription = (props) => {
    const { reportId } = props;
    const { getMedicinePrescriptionData, deletemedicine } = ReportApi();
    const [showMedicineData, setShowMedicineData] = useRecoilState(setMedicineData)
    const [item, setItem] = useState([]);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        getMedicineData()
    }, [])

    function getMedicineData() {
        getMedicinePrescriptionData(reportId)
            .then((result) => {
                setShowMedicineData(result);
            })
    }

    const handleDeleteShow = (item) => {
        setItem(item)
        setShowDelete(true)
    }

    const handleDeleteClose = () => {
        setShowDelete(false)
    }

    const handleDelete = () => {
        const medicineId = item._id
        deletemedicine(reportId, medicineId)
            .then(() => {
                const updatedData = showMedicineData.filter(medicine => medicine._id !== medicineId);
                setShowMedicineData(updatedData);
                handleDeleteClose()
            })
    }

    return (
        <>
            {showMedicineData && showMedicineData.length > 0 ?
                <div className='margin_top_15'>
                    <h6 align="left" className='patientModal ml-2'>Medicine List</h6>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'><span className='patientModal'>Index</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Medicine Name</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Take</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Duration</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Mg</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Slots</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Notes</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showMedicineData && showMedicineData.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='center'>{i + 1}</TableCell>
                                            <TableCell align='center'>{item.medicineName}</TableCell>
                                            <TableCell align='center'>{item.timing}</TableCell>
                                            <TableCell align='center'>{item.days}</TableCell>
                                            <TableCell align='center'>{item.mg}</TableCell>
                                            <TableCell align='center'>
                                                {item['frequency'].map((schedule, index) => {
                                                    return (
                                                        <>{schedule.schedule}, </>
                                                    )
                                                })}
                                            </TableCell>
                                            <TableCell align='center'>{item.note ? item.note : null}</TableCell>
                                            <TableCell align='center'>
                                                <div className="mt-2">
                                                    <Link
                                                        to="#"
                                                        onClick={() => handleDeleteShow(item)}
                                                        className=" editbutton">
                                                        <i className="icon-trash-2 editbutton"
                                                            title="Delete Medicine">
                                                        </i>
                                                    </Link>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                : null}
            <Modal show={showDelete} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor">
                        You want to delete this Medicine.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleDelete(item)}>
                        Yes
                    </Button>
                    <Button variant="default" className="borderStyle" onClick={handleDeleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default GetMedicinePriscription;