import React, { useEffect } from 'react';
import HelperList from './partial/helperList';
import { useParams, Link} from 'react-router-dom';
import { MainNav } from '../../mainComponent/mainNav';
import { Wrapper } from '../../mainComponent/Wrapper';
import UserLinks from '../Dashboard-card/partial/uselinks';
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import HelperApi from '../../services/HelperApi';
import { setHelper } from '../../recoil/atom/setHelper';
import { Button } from 'react-bootstrap';

export default function Helper() {
    const [helperList, setHelperList] = useRecoilState(setHelper);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const { doctorId } = useParams()
    let { getHelper } = HelperApi()

    useEffect(() => {
        getHelperDetails();
    }, [])

    async function getHelperDetails() {
        getHelper(doctorId)
            .then((result) => {
                if (result) {
                    const data = result.filter((helper) => {
                        if (helper.isDeleted === false) {
                            return helper
                        }
                    })
                    setHelperList(data)
                } else {
                    return null
                }
            })
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Assistant</li>
                    <li>
                        <Button className='appColor btn_sub'>
                            <Link to={`addhelper`}> Add Assistant</Link>
                        </Button>
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <HelperList doctorId={doctorId} />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
} 