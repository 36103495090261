import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import AuthApi from "../../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import { ShowCreateOtp } from "./showCreateOtp";
import { Wrapper } from "../../../mainComponent/Wrapper";
import { MainNav } from "../../../mainComponent/mainNav";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import UserLinks from "../../Dashboard-card/partial/uselinks";

export default function CreateNewDoctorMpin() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [loginData, setLoginData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState(false)
    const [showOTP, setShowOTP] = useState(false);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [doctorId] = useRecoilState(setDoctorId);
    console.log(doctorId)
    const { login } = AuthApi();

    const getOTPSection = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError(true)
        }
        else {
            login({ mobile })
                .then(response => {
                    if (response.data.isLoggedIn === true) {
                        setIsError('Mobile number already exist.')
                    }
                    else {
                        setShowOTP(true)
                        setMessage(true)
                        setIsError(false)
                        let item = response.data
                        setLoginData(item)
                    }
                })
        }
    };

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/newdoctor/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Create Account</li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <div className="container margin_60_35">
                        <div id="login-2">
                            <h1>Create Account</h1>
                            <form >
                                <div className="clearfix">
                                    <div className="last">
                                        <div className="row">
                                            <lable className="mb-2 fontSize">Mobile Number</lable>
                                            <div className="col-md-12 mb-2">
                                                <input
                                                    name="mobile"
                                                    value={mobile.mobile}
                                                    maxLength={10}
                                                    pattern="[+-]?\d+(?:[.,]\d+)?"
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    placeholder="Phone Number (+XX)"
                                                    className="form-control"
                                                />
                                                {message && (<span className="sendotp-message"> OTP is sent to the mobile number</span>)}
                                                <div className="validation">{isError}</div>
                                            </div>
                                            {showOTP === true ?
                                                <>
                                                    <ShowCreateOtp loginData={loginData} />
                                                    <Outlet />
                                                </>
                                                : <div className="">
                                                    <MainButtonInput onClick={getOTPSection}>Go</MainButtonInput>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {/* <div className="appcolor" align="right">
                                <Link to={`/newdoctor/${doctorId}`} >Already have account </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Outlet />
        </Wrapper >

    )
}