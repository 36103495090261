import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';
import AppointmentsApi from '../../services/AppointmentsApi';
import Person from '@mui/icons-material/Person';
import { Modal } from 'react-bootstrap';
import CalendarModalBox from './partial/CalendarModalBox';
import { Accordion, AccordionDetails, AccordionSummary, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { API } from '../../config';
import CloseIcon from '@mui/icons-material/Close';
import { MainButtonInput } from '../../mainComponent/mainButtonInput';
import { useNavigate } from 'react-router-dom';

export default function DateCalendarValue(props) {
    const { doctorId } = props;
    const [value, setValue] = useState(dayjs());
    const [appointmentDetails, setAppointmentDetails] = useState([]);
    const [clinicList, setClinicList] = useState([]);
    const [clinicNames, setClinicNames] = useState({});
    const [show, setShow] = useState(false);
    const { getAppointmentsDetails } = AppointmentsApi();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = (date = dayjs()) => {
        const data = {
            date: date,
            status: ["Ongoing", "Inprogress", "Incomplete"]
        }
        getAppointmentsDetails(doctorId, data)
            .then(async (result) => {
                setClinicList(result.groupDataByClinicId)
                const clinics = Object.keys(result.groupDataByClinicId);
                const getClinicNames = clinics.map(id =>
                    fetch(`${API}/getclinic/${id}`).then(res => res.json())
                );
                // Wait for all promises to resolve
                const clinicNames = await Promise.all(getClinicNames);
                const clinicNamesMap = {};
                clinicNames.forEach(name => {
                    clinicNamesMap[name._id] = name.clinicName;
                });
                setClinicNames(clinicNamesMap)
            });
    };

    const fetchEventsForDate = (date) => {
        const bookingDate = date.toISOString()
        fetchAppointments(bookingDate)
    };

    const handleModalButtonClick = (item) => {
        setAppointmentDetails(item);
        setShow(true);
        setOpen(true)
    };

    const handleClose = () => {
        setShow(false);
        setOpen(false)
    }

    const bookAppointment = () => {
        navigate(`/appointments/${doctorId}/bookappointment`)
    }
    return (
        <div className='col-lg-8'>
            <div className="white-box ">
                <div className="row">
                    {isMobile ? (
                        <Accordion defaultActiveKey="0">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="calendar-content"
                                id="calendar-header"
                            >
                                <h5>Appointments</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                        <DemoItem>
                                            <DateCalendar
                                                value={value}
                                                onChange={(newValue) => {
                                                    setValue(newValue);
                                                    fetchEventsForDate(newValue);
                                                }}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        // Calendar for desktop
                        <div className="col-md-6 calendar-container">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                    <DemoItem>
                                        <DateCalendar
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                                fetchEventsForDate(newValue);
                                            }}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    )}
                    <div className='col-md-6 scrollbar'>
                        <div className="" align='right'>
                            <MainButtonInput onClick={bookAppointment}>Schedule Appointment</MainButtonInput>
                        </div>
                        <h6 className='pt-2'>Appointments</h6>
                        {(Object.keys(clinicList).length === 0) === true ?
                            <p className='MuiTypography-h6 mt-3'>
                                Appointments are not available on this date.
                                Please select another day.
                            </p>
                            :
                            <div>
                                {Object.entries(clinicList).map(([clinicId, items], index) => (
                                    <div className='dashboardClinicList' key={clinicId}>
                                        <Accordion defaultActiveKey={index === 0 ? clinicId : undefined}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls={`calendar-content-${clinicId}`}
                                                id={`calendar-header-${clinicId}`}
                                            >
                                                <div align='left'>{clinicNames[clinicId]}</div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                                        <DemoItem>
                                                            {items ? (
                                                                items.map((item) => (
                                                                    < div
                                                                        key={item._id}
                                                                        className="calendarEvents row"
                                                                        onClick={() => handleModalButtonClick(item)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        <div className='fontSize color pl-1 col-md-9 lineHeight' align='left'>
                                                                            <Person className="personIcon fontSize color" />
                                                                            {item.dependentId ? item['dependentDetails'][0].name : item['patientDetails'][0].name}
                                                                        </div>
                                                                        <div className='fontSize color col-md-3 lineHeight' align='left'>{item.slotTime}</div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <p className='MuiTypography-h6 mt-3'>
                                                                    No appointments available. Please select another day.
                                                                </p>
                                                            )}
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>

                    {isMobile ? (
                        <Drawer anchor="bottom" open={open} onClose={handleClose}>
                            <div className='drawerTitle underline' >
                                <Typography variant="h6">Patient Details</Typography>
                                <IconButton onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className='p-4'>
                                <CalendarModalBox
                                    doctorId={doctorId}
                                    patientAppointment={appointmentDetails}
                                    onSubmit={handleModalButtonClick}
                                />
                            </div>
                        </Drawer>
                    ) : (
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Patient Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <CalendarModalBox
                                    doctorId={doctorId}
                                    patientAppointment={appointmentDetails}
                                    onSubmit={handleModalButtonClick}
                                />
                            </Modal.Body>
                        </Modal>
                    )
                    }
                </div>
            </div>
        </div >
    );
}