import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import { Wrapper } from '../../../mainComponent/Wrapper';
import UserLinks from '../../Dashboard-card/partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import { Button } from 'react-bootstrap';
import DoctorList from './doctorList';

export default function AddDoctor() {
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const { doctorId } = useParams()

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li className='float-none' style={{ fontSize: 'inherit' }} >Add Doctor</li>
                    <li>
                        <Button className='appColor btn_sub'>
                            <Link to={`creatempin`} >Add Doctors</Link>
                        </Button>
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <DoctorList doctorId={doctorId} />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
} 