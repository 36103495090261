import React, { useState, useEffect } from 'react';
import { KEYID, SECRETEKEY } from '../../../config'
import { Button, Modal } from "react-bootstrap";
import AppointmentsApi from '../../../services/AppointmentsApi';
import ReportApi from '../../../services/ReportApi';
import ServicesApi from '../../../services/servicesAPI';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import RenderRazorpay from '../RenderRazorpay/RenderRazorPay';
import AuthApi from '../../../services/AuthApi';

export default function CompletePayment(props) {
    const { reportId, appointmentId, doctorId, onSubmit } = props;
    const [total, setTotal] = useState(0);
    const [partialAmount, setpartialAmount] = useState();
    const [patientFees, setPatientFees] = useState();
    const [paidFees, setPaidFees] = useState(0);
    const [extraFees, setExtraFees] = useState(0);
    const [getServices, setGetServices] = useState(null);
    const [doctorName, setDoctorName] = useState(null);
    const [bookingInfo, setBookingInfo] = useState(null);
    const [show, setShow] = useState(false);
    const { getDrInfo } = AuthApi()
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const { UpdateStatusBookingdata, createPDF, getPaymentData } = AppointmentsApi()
    const { getDentalServicesPrescription } = ReportApi();
    const { paymentCapture, payment, getPaymentsByAppointmentId } = ServicesApi()
    const [orderDetails, setOrderDetails] = useState({
        orderId: null,
        currency: null,
        amount: null,
        payId: null
    });
    const totalAmount = parseInt(patientFees) + parseInt(total) + parseInt(extraFees)
    const pendingAmount = parseInt(totalAmount) - (parseInt(partialAmount) + parseInt(paidFees))

    useEffect(() => {
        getServicesData()
        paymentdata()
        getDoctorPersonalDetails()
        getPartialPayments()
    }, [])

    const handleShow = () => {
        setShow(true)
    }
    const handleClose = () => setShow(false)

    const paymentdata = () => {
        getPaymentData(appointmentId)
            .then(data => {
                setBookingInfo(data[0])
                setPatientFees(data[0]["fees"])
            })
    }
    
    const getDoctorPersonalDetails = () => {
        getDrInfo({ doctorId })
            .then((result) => {
                setDoctorName(result.result[0]["name"]);
            })
    }

    const getServicesData = () => {
        getDentalServicesPrescription({ reportId })
            .then((result) => {
                if (result) {
                    setGetServices(result)
                    const totalAmt = result.reduce((initialValue, curValue) => {
                        return initialValue + parseInt(curValue.fees)
                    }, 0)
                    setTotal(totalAmt)
                }
            })
    }

    const getPartialPayments = () => {
        getPaymentsByAppointmentId(appointmentId)
            .then((result) => {
                const partialPayments = result.reduce((initialValue, curValue) => {
                    return initialValue + parseInt(curValue.amount / 100)
                }, 0)
                setpartialAmount(partialPayments)
            })
    }

    const handlePaidFeesValue = (e) => {
        e.preventDefault();
        setPaidFees(e.target.value)
    }

    const handleExtraFeesValue = (e) => {
        e.preventDefault();
        setExtraFees(e.target.value)
    }

    const paymentOrderData = () => {
        const bodyData = {
            amount: paidFees * 100,
            appointmentId: appointmentId,
            transactionId: `pay_${appointmentId}`,
        }
        payment(bodyData)
            .then((data) => {
                const bodyData = {
                    "status": bookingInfo.status,
                    "payment": pendingAmount > 0 ? "Pending" : "Done",
                    "medicalReportId": reportId,
                    "paymentId": data.id
                }
                UpdateStatusBookingdata({ appointmentId }, bodyData)
                const details = {
                    "totalAmount": parseInt(patientFees) + parseInt(total) + parseInt(extraFees),
                    "pendingamount": parseInt(totalAmount) - (parseInt(partialAmount) + parseInt(paidFees)),
                    "doctorId": doctorId,
                    "orderId": data.order_id,
                    "paymentId": `pay_${appointmentId}`,
                    "signature": appointmentId,
                    "refId": appointmentId,
                    "status": pendingAmount > 0 ? "Partial" : "Completed",
                    "paymentMethod": "cash",
                    "_id": data.id //update payment 
                }
                paymentCapture(details)
            })
        //createPDF(reportId)
        onSubmit()
    }

    const handleCreateOrder = async () => {
        // createPDF(reportId)
        const bodyData = {
            amount: paidFees * 100,
            appointmentId: appointmentId,
            transactionId: ` ${appointmentId}_paycash`,
        }
        payment(bodyData)
            .then((data) => {
                if (data && data.order_id) {
                    setOrderDetails({
                        payId: data.id,
                        orderId: data.order_id,
                        currency: data.currency,
                        amount: data.amount,
                    });
                    setDisplayRazorpay(true);
                };
            })

    }
    return (
        <>
            {bookingInfo ? (
                <div className='paymentInput'>
                    <label>Consultation Fees : </label>
                    <span className='ml-2'>{bookingInfo.fees}</span>
                </div>
            ) : null}

            {getServices && getServices.map((item, i) => {
                return (
                    <div key={i} className='paymentInput'>
                        <label >{item.service_name} : </label>
                        <span className='ml-2'>{item.fees}</span>
                    </div>)
            })}
            <div className='border-payment' />

            <div className='paymentInput'>
                <label className='mt-2'>Total Amount : </label>
                <span className="ml-2 mt-2">{parseInt(totalAmount)}</span>
            </div>

            <div className='paymentInput'>
                <label className='mt-2'>Paid Amount : </label>
                <span className=" ml-2 mt-2">{partialAmount}</span>
            </div>

            <div className='paymentInput'>
                <label className='mt-2'>Pending Amount :</label>
                <span className="ml-2 mt-2">{parseInt(totalAmount) - parseInt(partialAmount)}</span>
            </div>

            <div className='border-payment' />

            <div className=' paymentInput'>
                <label className='mt-2'>Extra Fees : </label>
                <input
                    type="text"
                    value={extraFees}
                    onChange={handleExtraFeesValue}
                    className="payment otherInput"
                    name="extraFees"
                />
            </div>

            <div className=' paymentInput'>
                <label className='mt-2'>Enter Amount which you pay now : </label>
                <input
                    type="text"
                    value={paidFees}
                    onChange={handlePaidFeesValue}
                    className="payment otherInput"
                    name="OtherFees"
                />
            </div>

            <div className=' paymentInput'>
                <label className='mt-2'>Pending Amount:</label>
                <span className="ml-2 mt-2">{parseInt(totalAmount) - (parseInt(partialAmount) + parseInt(paidFees))}</span>
            </div>

            <div className='border-payment' />

            <div className='row'>
                <div className="mt-3 text-center">
                    <MainButtonInput
                        onClick={() => handleShow()}>
                        Cash Payment
                    </MainButtonInput>
                </div>

                <div className="mt-3 ml-2 text-center">
                    <div
                        className="disabled-div">
                        <label>Online Payment</label>
                    </div>
                    {/* <MainButtonInput
                        disabled={true}
                        onClick={handleCreateOrder}>
                        Online Payment
                    </MainButtonInput> */}
                </div>

                {displayRazorpay === true && (
                    <RenderRazorpay
                        payId={orderDetails.payId}
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        orderId={orderDetails.orderId}
                        keyId={KEYID}
                        keySecret={SECRETEKEY}
                        doctorName={doctorName}
                        reportId={reportId}
                        appointmentId={appointmentId}
                        doctorId={doctorId}
                        refId={appointmentId}
                        pendingamount={parseInt(totalAmount) - (parseInt(partialAmount) + parseInt(paidFees))}
                        totalAmount={parseInt(patientFees) + parseInt(total)}
                        // onChange={onChange}
                    />
                )}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor" >Did you receive complete payment?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => paymentOrderData()}>
                        Yes
                    </Button>
                    <Button variant="default" className='lightbuttonColor borderStyle'  onClick={() => paymentOrderData()}>
                        Due
                    </Button>
                    <Button variant="default" className='lightbuttonColor borderStyle'  onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}