import React, { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { Link } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ReactPaginate from "react-paginate";
import LabWorkApi from '../../../services/LabWorkApi';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import { Modal, Button } from "react-bootstrap";
import LabWorkView from '../report/LabWorkView';
import UpdateLabWorkTable from './updateLabworktable';
import { useRecoilState } from 'recoil';
import { setLabworkData } from '../../../recoil/atom/setLabWorkData';
import DeleteIcon from '@mui/icons-material/Delete';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function GetLabWork(props) {
    const { doctorId } = props
    const [labWorkData, setLabWorkData] = useRecoilState(setLabworkData);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [showData, setShowData] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showLabData, setShowLabData] = useState(false);
    const [labWorkId, setLabWorkId] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeModal, setActiveModal] = useState();
    const isMobile = useMediaQuery('(max-width:768px)')
    const paginationRef = useRef(currentPage);
    const pageSize = 5;
    const { getlabworkbydoctorId, deleteLabWork } = LabWorkApi()

    useEffect(() => {
        getLabWorkData(currentPage);
    }, [])

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const openDrawerWithModal = (modalType, id) => {
        setLabWorkId(id)
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const renderModalContent = () => {
        switch (activeModal) {
            case 'labworkview':
                return <LabWorkView
                    labWorkId={labWorkId}
                    onSubmit={handleDataClose}
                />
            case 'updatelabworktable':
                return <UpdateLabWorkTable
                    doctorId={doctorId}
                    labWorkId={labWorkId}
                    onSubmit={onDataLabFormSubmit}
                />
            default:
                return null;
        }
    };

    const getLabWorkData = (currentPage) => {
        const data = {
            page: currentPage,
            pageSize: pageSize,
        }
        getlabworkbydoctorId(doctorId,data)
            .then((result) => {
                if (result.data) {
                    setTotalPages(result.totalPages)
                    setLabWorkData(result.data)
                } else {
                    return null
                }
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getLabWorkData(data.selected + 1)
    }

    const handleDataClose = () => {
        setShowData(false)
        setDrawerOpen(false)
    }

    const handleDataShow = (id) => {
        setLabWorkId(id)
        setShowData(true)
    }

    const handleLabDataClose = () => setShowLabData(false)

    const handleLabDataShow = (id) => {
        setLabWorkId(id)
        setShowLabData(true)
    }

    const onDataLabFormSubmit = () => {
        handleLabDataClose()
    };

    const handleDeleteClose = () => setShowDelete(false);

    const handleDeleteShow = (id) => {
        setLabWorkId(id)
        setShowDelete(true)
    }

    function deleteLabwork(id) {
        deleteLabWork(id)
            .then(() => {
                getLabWorkData()
                handleDeleteClose()
            })
    }
    return (
        <>
            {labWorkData && labWorkData.length > 0 ? <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="font_weight" align="left">Work Name</TableCell>
                                <TableCell className="font_weight" align="left">Work Type</TableCell>
                                <TableCell className="font_weight" align="left">Lab Name</TableCell>
                                <TableCell className="font_weight" align="left">Lab Contact</TableCell>
                                <TableCell className="font_weight" align="left">Given Date</TableCell>
                                <TableCell className="font_weight" align="left">View Details</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {labWorkData && labWorkData.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell align="left">
                                            {item.workname}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.worktype}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.labname}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item.labcontact}
                                        </TableCell>

                                        <TableCell align="left">
                                            {moment(item.givendate).format("DD-MM-YYYY")}
                                        </TableCell>

                                        <TableCell align="left">
                                            <Link onClick={() => isMobile ? openDrawerWithModal('labworkview', item._id) : handleDataShow(item._id)}>
                                                <VisibilityIcon style={{ fontSize: 20 }} />
                                            </Link>
                                            <Link onClick={() => isMobile ? openDrawerWithModal('updatelabworktable', item._id) : handleLabDataShow(item._id)}>
                                                <ModeEditOutlineSharpIcon style={{ marginLeft: 15, fontSize: 20 }} />
                                            </Link>
                                            <Link onClick={() => handleDeleteShow(item._id)}>
                                                <DeleteIcon style={{ marginLeft: 15, fontSize: 20 }} />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer >
                <div className='mt-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={totalPages}
                        previousLabel="< Previous"
                        renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        containerClassName="pagination "
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        activeClassName="active"
                        forcePage={currentPage - 1}
                    />
                </div>
            </> : <div className="clinicHistory font_weight" >Add your work here</div>}

            {isMobile ? (
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='p-4'>
                        <div align='right'>
                            <IconButton onClick={handleDrawerToggle}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-2'>
                            {renderModalContent()}
                        </div>
                    </div>
                </Drawer>
            )
                : (
                    <>
                        <Modal show={showData} onHide={handleDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Work Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <LabWorkView
                                    labWorkId={labWorkId}
                                    onSubmit={handleDataClose}
                                />
                            </Modal.Body>
                        </Modal>
                        <Modal show={showLabData} onHide={handleLabDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Work Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UpdateLabWorkTable
                                    doctorId={doctorId}
                                    labWorkId={labWorkId}
                                    onSubmit={onDataLabFormSubmit}
                                />
                            </Modal.Body>
                        </Modal>
                        <div>
                            <Modal show={showDelete} onHide={handleDeleteClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Are you sure?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert modalColor">You want to delete this labwork.</div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="default" className='appColor' onClick={() => deleteLabwork(labWorkId)}>
                                        Yes
                                    </Button>
                                    <Button variant="default" className='borderStyle' onClick={handleDeleteClose}>
                                        No
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
                )}

        </>
    )
} 