
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import MedicineApi from "../../../services/MedicineApi"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useRecoilState } from "recoil";
import { setDoctorMedicine } from "../../../recoil/atom/setDoctorMedicine"
import DeleteIcon from '@mui/icons-material/Delete';
import { Drawer, IconButton, useMediaQuery, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Loader from "../../Dashboard-card/Loader";
import { InsertMedicine } from "./InsertMedicine";

function AddMedicine(props) {
    const { doctorId } = props
    const [showMedicine, setShowMedicine] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [medicineId, setMedicineId] = useState('');
    const [getMedicine, setGetMedicine] = useRecoilState(setDoctorMedicine);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const { fetchMedicine, deleteDocotrMedicine } = MedicineApi()
    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:768px)')
    const paginationRef = useRef(currentPage)
    const pageSize = 10;

    useEffect(() => {
        getMedicines(currentPage)
    }, []);

    const handleMedicineClose = () => setShowMedicine(false)
    const handleMedicineShow = () => setShowMedicine(true)

    const handleDeleteClose = () => setShowDelete(false);

    const handleDeleteShow = (id) => {
        setMedicineId(id)
        setShowDelete(true)
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getMedicines(data.selected + 1)
    }

    const getMedicines = (currentPage) => {
        setIsLoading(true);
        const data = {
            page: currentPage,
            pageSize: pageSize,
        }
        fetchMedicine(doctorId, data)
            .then((res) => {
                setGetMedicine(res.medicineDetails)
                setTotalPages(res.totalPages)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    function deleteMedicine(medicineId) {
        deleteDocotrMedicine(medicineId)
            .then(() => {
                getMedicines(currentPage)
                handleDeleteClose()
            })
    }

    return (
        <div >
            <div align='right'>
                <MainButtonInput className='align-left' onClick={handleMedicineShow}>
                    ADD MEDICINE
                </MainButtonInput>
            </div>
            {isMobile ? (
                <Drawer anchor="bottom" open={showMedicine} onClose={handleMedicineClose}>
                    <div className='p-4'>
                        <div className='drawerTitle underline'>
                            <Typography variant="h6" >Add Medicine</Typography>
                            <IconButton onClick={handleMedicineClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-2'>
                            <InsertMedicine
                                doctorId={doctorId}
                                onSubmit={handleMedicineClose} />
                        </div>
                    </div>
                </Drawer>
            )
                : (
                    <>
                        <div className="modalbtn">
                            <Modal show={showMedicine} onHide={handleMedicineClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Medicine</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <InsertMedicine doctorId={doctorId} onSubmit={handleMedicineClose} />
                                </Modal.Body>
                            </Modal>
                        </div>
                    </>
                )}

            <>
                {isLoading ?
                    <div className='loader-container'>
                        <Loader />
                    </div>
                    :
                    <>
                        {getMedicine && getMedicine.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font_weight" align="left">Serial No.</TableCell>
                                            <TableCell className="font_weight" align="left">Medicine Name</TableCell>
                                            <TableCell className="font_weight" align="left">Mg</TableCell>
                                            <TableCell className="font_weight" align="left">Actions </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getMedicine && getMedicine.map((item, index) => {
                                            return (
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell align="left">
                                                            {(currentPage - 1) * pageSize + (index + 1)}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.medicineName}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item.mg}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            <Link onClick={() => handleDeleteShow(item._id)}>
                                                                <DeleteIcon style={{ fontSize: 20 }} />
                                                            </Link>
                                                        </TableCell>
                                                    </TableRow>

                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer >
                            :
                            <div className="clinicHistory font_weight" >Add your Medicines</div>
                        }
                    </>
                }
            </>
            <div className="mt-5">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={totalPages}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    marginPagesDisplayed={2}
                    containerClassName="pagination "
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    activeClassName="active"
                    forcePage={currentPage - 1}
                />
            </div>
            <div>
                <Modal show={showDelete} onHide={handleDeleteClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert modalColor" >You want to delete this Medicine.</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => deleteMedicine(medicineId)}>
                            Yes
                        </Button>
                        <Button variant="default" className="borderStyle" onClick={handleDeleteClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </div >

    )
}

export { AddMedicine }