import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Loader from '../../Dashboard-card/Loader';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import AuthApi from '../../../services/AuthApi';

export default function DoctorList(props) {
    const { doctorId } = props;
    const navigate = useNavigate();
    const [doctorList, setDoctorList] = useState(null);
    const { getDrInfo } = AuthApi()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getAllDoctors();
    }, []);
   
    const getAllDoctors = () => {
        getDrInfo({ doctorId })
            .then((jsonRes) => {
                setDoctorList(jsonRes.result[0]["doctorList"])
                setIsLoading(false)
            })
    }

    const handleShow = (e,id) => {
        e.preventDefault()
        navigate(`clinics/${id}`)
    }
   
    return (
        <>
            {isLoading === true ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {doctorList && doctorList.length > 0 ?
                        <div className='row'>
                            {doctorList.map((details, i) => {
                                return (
                                    <div key={i} className="col-md-4">
                                        <div className="cardDiv">
                                            <span className='cardSpan'>
                                                <i className='icon-user color patientListIcon' />
                                                <span className='patientName'>{details.name}</span>
                                            </span>
                                            <span className='cardSpan'>
                                                <i className='icon-mobile-1 color patientListIcon' />
                                                <span className='patinetInfo'>{details.mobile}</span>
                                            </span>
                                            <span className='cardSpan '>
                                                <i className='icon-email color patientListIcon' />
                                                <span className='patinetInfo '> {details.personalEmail}</span>
                                            </span>

                                            <div className="row justify-end top_border">
                                                <div className="mt-3 mr-2">
                                                    <Link onClick={(e) => handleShow(e,details._id)}>
                                                        <MainButtonInput>Continue</MainButtonInput>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <div className="clinicHistory font_weight" >Please add new Doctor</div>
                    }
                </>
            }
        </>
    )
}