import React, { useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { useRecoilState } from "recoil";
import appLogo from '../img/small_wecurify_logo@3x.png'
import { setHelperData } from "../recoil/atom/setHelperData";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { GiHamburgerMenu } from "react-icons/gi";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import AuthApi from "../services/AuthApi";
import { setReportsId } from "../recoil/atom/setReportId";

export default function Header() {
    const [showMenu, setShowMenu] = useState(false)
    const [doctorId, setDoctor] = useRecoilState(setDoctorId);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [patientId, setPatientsId] = useRecoilState(setNewPatientId)
    const [report, setReportId] = useRecoilState(setReportsId)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [unreadCount, setUnreadCount] = useState();
    const navigate = useNavigate();
    const { getNotification } = AuthApi();

    const handleLogout = (e) => {
        e.preventDefault();
        navigate("/")
        setDoctor("")
        setHelpersData('')
        setPatientsId('')
        setReportId('')
        setIsDropdownOpen(false)
    }

    // const showNotification = () => {
    //     if (doctorId) {
    //         getNotification(doctorId)
    //             .then((res) => {
    //                 if (res) {
    //                     const unread = res.filter(item => !item.read).length
    //                     setNotification(res);
    //                     setUnreadCount(unread);
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error("Error fetching notifications:", error);
    //             });
    //     } else {
    //         return null;
    //     }
    // };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        handleMarkAsRead()
    };
    const handleMarkAsRead = () => {
        setUnreadCount(0);
    };

    return (
        <header style={{ zIndex: '2' }} className="header_sticky">
            <div className="hamburger-menu">
                <Link to='#'
                    onClick={() => setShowMenu(!showMenu)}
                    className="btn_mobile"
                >
                    {doctorId ?
                        <>
                            <GiHamburgerMenu
                                color='#1a3c8b' />

                            <div className={showMenu ? null : "dash "}>
                                <UserLinks
                                    doctorId={doctorId}
                                    helperId={helpersData._id}
                                    accessModule={helpersData.access_module}
                                />
                            </div>
                        </>
                        : null}
                </Link>
            </div>

            <div className="notification-bell container">
                <div className="row">
                    <div className="col-6">
                        <Link to={"/"}>
                            <div id="logo_home" align='left'>
                                <img className='appLogo' src={appLogo} alt="Something Went Wrong" />
                            </div>
                        </Link>
                    </div>
                    <div className=" col-6">
                        <nav className="main-menu" id="menu">
                            <ul className="d-flex">
                                {doctorId ?
                                    <>
                                        {/* <li className="fontS ">
                                            <Link onClick={toggleDropdown}>
                                                <i className="mb-2 icon-bell-alt"></i> */}
                                        {/* {unreadCount > 0 && (
                                                    <span className="badge">{unreadCount}</span>
                                                )} */}
                                        {/* </Link> */}
                                        {/* </li> */}
                                        <li className="font_weight fontSize ">
                                            <Link onClick={(e) => handleLogout(e)}>
                                                Logout
                                            </Link>
                                        </li>
                                    </>
                                    : null
                                    // <li className="fontSize"><Link className='margin_top_15' to="/"><b>Login</b></Link></li>
                                }
                            </ul>
                        </nav>
                        {/* {isDropdownOpen && (
                            <div className="notifications-dropdown open">
                                {notification.length > 0 ? (
                                    <ul>
                                        {notification.map((item) => (
                                            <li key={item.id}>
                                                {item.notification[0].body}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <li className="no-notifications">No new notifications</li>
                                )}
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </header>
    )
}