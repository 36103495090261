import { useState, useEffect } from "react";
import PatientProfile from "../../../img/profile.png"
import { Link, useNavigate } from "react-router-dom";
import PatientApi from "../../../services/PatientApi";
import ReportApi from "../../../services/ReportApi";
import AppointmentsApi from "../../../services/AppointmentsApi";
import { Button, Modal } from "react-bootstrap";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";

function CalendarModalBox(props) {
    const { doctorId, patientAppointment } = props;
    const [patientDetails, setPatientDetails] = useState([]);
    const [showCancel, setCancelDelete] = useState(false);
    const { MedicineReportData } = ReportApi()
    const { patientDetailsData } = PatientApi()
    const { updateIncompleteStatus, cancelPatientAppointment } = AppointmentsApi()
    const navigate = useNavigate()

    useEffect(() => {
        getPatientInfoById();
    }, [])

    function startConsultation() {
        const bodyData = {
            "doctorId": doctorId,
            "patientId": patientAppointment.patientId,
            'patientAppointmentId': patientAppointment._id,
            'clinicId': patientAppointment.clinicId,
            "fees": patientAppointment.fees
        }
        MedicineReportData(bodyData)
            .then((res) => {
                const bodyData = {
                    'status': "Incomplete"
                }
                updateIncompleteStatus(patientAppointment._id, bodyData)
                navigate(`/appointments/${doctorId}/consultation/${res._id}`, { state: patientAppointment.fees })
            })
    };

    function resumeConsultation() {
        const bodyData = {
            "doctorId": doctorId,
            "patientId": patientAppointment.patientId,
            'patientAppointmentId': patientAppointment._id,
            'clinicId': patientAppointment.clinicId,
            "fees": patientAppointment.fees
        }
        MedicineReportData(bodyData)
            .then((res) => {
                navigate(`/appointments/${doctorId}/consultation/${res._id}`, { state: patientAppointment.fees })
            })
    };

    const handleCancelShow = () => {
        setCancelDelete(true)
    }

    const handleCancelClose = () => setCancelDelete(false)

    const cancelAppointment = () => {
        cancelPatientAppointment(patientAppointment._id)
            .then(() => {
                handleCancelClose();
            })
    }

    const getPatientInfoById = () => {
        const patientId = patientAppointment.patientId
        patientDetailsData({ patientId })
            .then(jsonRes => {
                setPatientDetails(jsonRes[0])
            })
    };

    return (
        <div>
            <div className="d-flex container " >
                <div className=" mx-2 ">
                    <img src={PatientProfile} alt="Patient Profile" />
                </div>

                <div className="align-item-right">
                    <div className=" patientModalName align-item-right ">
                        {patientDetails.name}
                    </div>
                    <div>
                        <b className="patientModal">Email : </b>
                        {patientDetails.email}
                    </div>
                    <div>
                        <b className="patientModal">Gender : </b>
                        {patientDetails.gender}
                    </div>
                    <div>
                        <b className="patientModal">Mobile No :  </b>
                        {patientDetails.mobile}
                    </div>
                    <div>
                        <b className="patientModal">Age :    </b>
                        {patientDetails.age}
                    </div>
                </div>
            </div>
            <div className="row mt-3 justify-end top_border">
                <div className='mt-3'>
                    {patientAppointment.status !== "Cancelled" ?
                        <>
                            {patientAppointment.status === "Ongoing" ?
                                <MainButtonInput onClick={() => startConsultation()}>Start Consultation</MainButtonInput>
                                :
                                <MainButtonInput onClick={() => resumeConsultation()}>Resume Consultation</MainButtonInput>
                            }
                        </>
                    : null }
                        
                </div>
                <div className='mt-3 ml-2'>
                    {patientAppointment.status === "Ongoing" ?
                        <SecondaryButtonInput onClick={() => handleCancelShow()}>Cancel Appointment</SecondaryButtonInput>
                        :
                        <div align="right" className="mr-2 validation">
                            {patientAppointment.status}
                        </div>
                    }
                </div>
            </div>

            <Modal show={showCancel} onHide={handleCancelClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor alert-bgcolor">
                        You want to cancel this appointment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={cancelAppointment}>
                        Yes
                    </Button>
                    <Button variant="default" className='appColorBorder' onClick={handleCancelClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default CalendarModalBox