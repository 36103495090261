import React, { useEffect, useState } from "react";
import ReportApi from "../../../services/ReportApi";
import { toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { useRecoilState } from "recoil";
import { setMedicineData } from "../../../recoil/atom/setMedicineData";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';
import { THEME_COLOR } from "../../../config";
import MedicineApi from "../../../services/MedicineApi";

export default function AddMedicineModalBox(props) {
    const { onSubmit, reportId, doctorId } = props;
    const [tabletName, setTabletName] = useState([]);
    const [medicineSave, setMedicineSave] = useState();
    const [values, setValues] = useState('');
    const [selectedSchedule, setSelectedSchedule] = useState([]);
    const [checked, setChecked] = useState([false, false, false, false]);
    const [saveMealData, setSaveMealData] = useState([]);
    const [medicineData, setMedicinedata] = useRecoilState(setMedicineData)
    const { insertMedicinePrescriptionData } = ReportApi();
    const { fetchMedicine } = MedicineApi();
    const StyledCheckbox = withStyles({
        root: {
            '&$checked': {
                color: THEME_COLOR,
            },
        },
        checked: {},
    })(Checkbox);
    const meal = [
        {
            "_id": 0,
            "name": "Before Meal"
        },
        {
            "_id": 1,
            "name": "After Meal"
        }
    ]
    const medicineSchedule = ['Morning', 'Afternoon', 'Evening', 'Night'];

    useEffect(() => {
        getMedicines()
    }, []);


    const getMedicines = () => {
        fetchMedicine(doctorId)
            .then((res) => {
                setTabletName(res.medicineDetails)
            })
            .catch((error) => {
                console.error("Failed to fetch data", error);
            })

    }

    const saveData = (e) => {
        e.preventDefault()
        const bodyData = {
            "reportId": reportId,
            "medicineName": medicineSave,
            "days": values.duration,
            "timing": saveMealData,
            "frequency": selectedSchedule,
            "note": values.note,
            "mg": values.mg
        }
        insertMedicinePrescriptionData(bodyData)
            .then((res) => {
                setMedicinedata(medicineData.concat(res))
                onSubmit()
            })
        toast.success("Saved Successfully!")
    }
    const handleMealData = ((e, selectedValue) => {
        e.preventDefault()
        setSaveMealData(selectedValue.name)
    })

    const handleChange = (event, selectedValue) => {
        event.preventDefault()
        setMedicineSave(selectedValue.medicineName)
    }
    const handleFrequencyChange = (event, index) => {
        const isChecked = event.target.checked;

        const newCheckedState = [...checked];
        newCheckedState[index] = isChecked;
        setChecked(newCheckedState);

        let updatedSchedule = [...selectedSchedule];

        if (isChecked) {
            updatedSchedule.push({
                schedule: medicineSchedule[index],
            });
        } else {
            updatedSchedule = updatedSchedule.filter(
                (item) => item.schedule !== medicineSchedule[index]
            );
        }

        setSelectedSchedule(updatedSchedule);
    };

    const handleInputChange = event => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    return (
        <>
            <form onSubmit={saveData} >
                <div className="">
                    <div className="mb-2">
                        <label className="font_weight">Medicine Name</label>
                        <Autocomplete
                            id={tabletName._id}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={medicineSave}
                            onChange={handleChange}
                            getOptionLabel={(data) => `${data.medicineName}`}
                            options={tabletName}
                            noOptionsText={"Medicine not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Medicine Name"
                                />}
                        />
                        <label className="col-md-7 mt-2 font_weight" >Take</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            id={saveMealData._id}
                            value={saveMealData.name}
                            onChange={handleMealData}
                            getOptionLabel={(meal) => `${meal.name}`}
                            options={meal}
                            renderInput={(params) => <TextField {...params} label="Select" />}
                        />
                    </div>

                    <div className="row">
                        <div className="mb-2 col-md-6">
                            <label className="font_weight">Duration</label>
                            <input
                                type="text"
                                defaultValue={5}
                                value={values.duration}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="5"
                                name="duration" />
                        </div>
                        <div className=" mb-2 col-md-6">
                            <label className="font_weight">Mg </label>
                            <input
                                type="text"
                                value={values.mg}
                                onChange={handleInputChange}
                                className="form-control"
                                placeholder="500"
                                name="mg" />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="font_weight">Note</label>
                    <textarea
                        type="text"
                        value={values.note}
                        onChange={handleInputChange}
                        className="form-text"
                        placeholder='Optional'
                        name="note"
                    />
                </div>
                <label className="font_weight mt-2">Frequency</label>
                <div className="row">
                    {medicineSchedule.map((schedule, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <StyledCheckbox
                                    checked={checked[index]}
                                    onChange={(event) => handleFrequencyChange(event, index)}
                                    value={schedule}
                                />
                            }
                            label={schedule}
                        />
                    ))}
                </div>
                <div className="text-center add_top_30">
                    <MainButtonInput>Save</MainButtonInput>
                </div>
            </form>
        </>
    )
}