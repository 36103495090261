import React, { useState } from "react";
import { MainInput } from "../../../mainComponent/mainInput";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { useRecoilState } from "recoil";
import MedicineApi from "../../../services/MedicineApi";
import { setDoctorMedicine } from "../../../recoil/atom/setDoctorMedicine";

const InsertMedicine = (props) => {
    const { doctorId } = props
    const [medicine, setMedicine] = useState([]);
    const [getMedicine, setGetMedicine] = useRecoilState(setDoctorMedicine);
    const { insertMedicine } = MedicineApi()

    function handleChange(event) {
        const { name, value } = event.target;
        setMedicine(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    const Save = (e) => {
        e.preventDefault();
        const bodyData = {
            doctorId: doctorId,
            medicineName: medicine.medicineName,
            mg: medicine.mg
        }
        insertMedicine(doctorId, bodyData)
            .then((res) => {
                setGetMedicine(getMedicine.concat(res))
            });
        props.onSubmit()
    }

    return (
        <div className="width_100">
            <form onSubmit={Save}>
                <div className="form-group">
                    <label className="font_weight">Medicine Name</label>
                    <MainInput
                        type="text"
                        name="medicineName"
                        onChange={handleChange}
                        value={medicine.name}
                        placeholder="Medicine name">
                    </MainInput>
                </div>

                <label className="font_weight">Mg</label>
                <MainInput
                    name="mg"
                    value={medicine.mg}
                    onChange={handleChange}
                    placeholder="Mg">
                </MainInput>

                <div className="text-center m-3">
                    <MainButtonInput value="Save" />
                </div>
            </form >
        </div >
    );
};
export { InsertMedicine }
