import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { setDependentId } from "../recoil/atom/setDependentId";
import { setFetchPatientData } from "../recoil/atom/setFetchPatientData";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { Button, Modal } from "react-bootstrap";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import moment from "moment";
import PatientApi from "../services/PatientApi";
import AuthApi from "../services/AuthApi";

function WalkingSlot(props) {
    const { doctorId, clinicId } = props;
    const [doctorName, setDoctorName] = useState([]);
    const [walkingShow, setWalkingShow] = useState(false);
    const [patientId, setPatientsId] = useRecoilState(setNewPatientId)
    const [dependentId, setDependentsId] = useRecoilState(setDependentId)
    const [DoctorId] = useRecoilState(setDoctorId)
    const [fetchPatientData] = useRecoilState(setFetchPatientData)
    const { paymentInfo } = PatientApi();
    const { getDrInfo } = AuthApi();
    const navigate = useNavigate()

    useEffect(() => {
        getDoctorData()
    }, []);

    function getDoctorData() {
        getDrInfo({ doctorId })
            .then((response) => {
                const name = response.result[0].name
                let fullName = name.split(' '),
                    firstName = fullName[0],
                    lastName = fullName[fullName.length - 1];
                setDoctorName("Dr. " + lastName)
            })
    }

    const handleWalkingShow = () => {
        setWalkingShow(true)

    }
    const handleWalkingClose = () => setWalkingShow(false)

    const handleWalkingSlot = () => {
        const currentDate = moment().format("YYYY-MM-DD")
        const startDate = moment().format("YYYY-MM-DD HH:mm")
        const currentTime = moment().format("HH:mm")
        const currentDateFormatted = moment().format('ddd DD MMM');
        const transactionData = {
            "DoctorId": doctorId,
            "ClinicId": clinicId,
            // "slotId": item._id,
            "patientId": patientId,
            "dependentId": dependentId !== " " ? dependentId : null,
            "transactionId": '123',
            "currency": 'INR',
            "date": currentDateFormatted,
            // "day": session.day,
            "slotTime": currentTime,
            "selectedDate": currentDate,
            "timeSlot": '20',
            "startDate": startDate,
            "status": "Ongoing",
            "payment": "hold",
            "patientmobile": fetchPatientData.mobile,
            "doctorname": doctorName,
            "parent": DoctorId,
        }
        paymentInfo(transactionData)
            .then((res) => {
                navigate(`/appointments/${DoctorId}`);
                setDependentsId(' ')
                setPatientsId(' ')
            })
            .catch((error) => {
                console.error('Payment error:', error);
            });
    }

    return (
        < >
            <span className="font_weight mt-2">WALKING </span>
            <div className="radiobutton justifyContent" >
                <Link
                    to='#'
                    onClick={handleWalkingShow}
                    className="btn_1"
                    type="radio"
                >
                    <label>{moment().format("HH:mm")}</label>
                </Link>
            </div>
            <Modal show={walkingShow} onHide={handleWalkingClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor ">You want to book this appointment.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={handleWalkingSlot}>
                        Yes
                    </Button>
                    <Button variant="default" className='borderStyle' onClick={handleWalkingClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
export { WalkingSlot }