
import React, { useEffect, useState } from "react";
import InventoryApi from "../../../services/InventoryApi";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import moment from "moment";

export default function ViewOutwardData(props) {
    const { inwardId } = props
    const [outwardData, setOutwardData] = useState(null)
    const { getSingleInwardDetails } = InventoryApi();

    useEffect(() => {
        onwardData()
    }, [])

    const onwardData = () => {
        getSingleInwardDetails(inwardId)
            .then((res) => {
                setOutwardData(res.outward)
            })
    }

    return (
        <div className="patientDataStyle">
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="font_weight" align="left">Index</TableCell>
                                <TableCell className="font_weight" align="left">Used Quantity</TableCell>
                                <TableCell className="font_weight" align="left">Outward Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {outwardData && outwardData.map((item, index) => {
                                return (
                                    <>
                                        {item ? (
                                            <TableRow key={index}>
                                                <TableCell align="left">
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {moment(item.outwardDate).format("DD-MM-YYYY")}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {item.usedStock}
                                                </TableCell>
                                            </TableRow>
                                        ) : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </>

        </div>
    )
}