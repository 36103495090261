import React, { useEffect, useState, useRef } from "react";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import ReactPaginate from "react-paginate";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import Loader from '../Loader';
import InventoryApi from "../../../services/InventoryApi";
import ViewOutwardData from "./ViewOutwordData";
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Modal } from "react-bootstrap";

export default function Outwards() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [inwardData, setInwardData] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [view, setView] = useState(false)
    const paginationRef = useRef(currentPage);
    const [inwardId, setInwardId] = useState()

    const pageSize = 10;
    const { getInwardDetails } = InventoryApi();
    const isMobile = useMediaQuery('(max-width:  768px)')

    useEffect(() => {
        getInwardData(currentPage);
    }, [])

    const getInwardData = (currentPage) => {
        setIsLoading(true)
        const data = {
            page: currentPage,
            pageSize: pageSize,
        }
        getInwardDetails(doctorId, data)
            .then((result) => {
                setTotalPages(result.totalPages)
                setInwardData(result.inwardDetails)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getInwardData(data.selected + 1)
    }

    const handleViewClose = () => {
        setView(false)
    }

    const handleView = (item) => {
        setInwardId(item)
        setView(true)
    }

    return (
        <>
            <Wrapper>
                <MainNav>
                    <div className="clearfix row">
                        <div className="width50">
                            <ul className="clearfix">
                                <li className='float-none' style={{ fontSize: 'inherit' }}>Outward Report</li>
                            </ul>
                        </div>
                    </div>
                </MainNav>

                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>

                    <div className='width_84'>
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <div className='common_box'>
                                {inwardData && inwardData.length > 0 ?
                                    <>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="font_weight" align="left">Item Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Manufacturer Name</TableCell>
                                                        <TableCell className="font_weight" align="left">Outward Date</TableCell>
                                                        <TableCell className="font_weight" align="left">Used Quantity</TableCell>
                                                        <TableCell className="font_weight" align="left">View</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {inwardData && inwardData.map((item, index) => {
                                                        return (
                                                            <>
                                                                {item['outward'] && item['outward'].length > 0 ? (
                                                                    <TableRow key={index}>
                                                                        <TableCell align="left">
                                                                            {item.itemName}
                                                                        </TableCell>

                                                                        <TableCell align="left">
                                                                            {item.manufacturerName}
                                                                        </TableCell>

                                                                        <TableCell align="left">
                                                                            {moment(item['outward'][0].outwardDate).format("DD-MM-YYYY")}
                                                                        </TableCell>

                                                                        <TableCell align="left">
                                                                            {item.totalUsedStock}
                                                                        </TableCell>

                                                                        <TableCell align="left">
                                                                            <Link onClick={() => handleView(item._id)}>
                                                                                <VisibilityIcon style={{ fontSize: 20 }} />
                                                                            </Link>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : null}
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div className='mt-5'>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={5}
                                                pageCount={totalPages}
                                                previousLabel="< Previous"
                                                renderOnZeroPageCount={null}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination "
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                activeClassName="active"
                                                forcePage={currentPage - 1}
                                            />
                                        </div>
                                    </> :
                                    <div className="clinicHistory font_weight" align='center' >
                                        Outward details are not available
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <>
                    {isMobile ? (
                        <Drawer anchor="bottom" open={view} onClose={handleViewClose}>
                            <div className='drawerTitle underline' >
                                <Typography variant="h6">Outward Details</Typography>
                                <IconButton onClick={handleViewClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className='p-4'>
                                <ViewOutwardData
                                    inwardId={inwardId}
                                    onSubmit={handleViewClose}
                                />
                            </div>
                        </Drawer>
                    ) : (
                        <div className="modalbtn">
                            <Modal show={view} onHide={handleViewClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Outward Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <ViewOutwardData
                                        inwardId={inwardId}
                                        onSubmit={handleViewClose}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                </>
            </Wrapper>

        </>
    )
}