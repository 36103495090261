import React, { useEffect, useState } from "react";
import { DoctorAppointmentType } from "../patient/doctorAppointmentType";
import { Topbar } from "../mainComponent/MainAccordion";
import { FaClinicMedical } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import { useRecoilState } from "recoil";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { MainNav } from "../mainComponent/mainNav";
import { setHelperData } from "../recoil/atom/setHelperData";
import { Wrapper } from "../mainComponent/Wrapper";
import Loader from "../doctor/Dashboard-card/Loader";
import ClinicApi from "../services/ClinicApi";
import AuthApi from "../services/AuthApi";
import { MainSelect } from "../mainComponent/mainSelect";
import { WalkingSlot } from "./walkingSlot";

function AppointmentBookingSection() {
    const { clinicId } = useParams();
    const [doctorId] = useRecoilState(setDoctorId)
    const [clinicData, setClinicData] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const [doctors, setDoctors] = useState(null)
    const [helpersData] = useRecoilState(setHelperData)
    const [isLoading, setIsLoading] = useState(true);
    const { getClinicDataById } = ClinicApi();
    const { getDrInfo } = AuthApi();
    const navigate = useNavigate();

    useEffect(() => {
        getclinic()
        doctorData()
    }, [selectedValue])

    const doctorData = () => {
        getDrInfo({ doctorId })
            .then((res) => {
                const doctorList = res.result[0]["doctorList"]
                const filterDoctors = doctorList.filter((item) => {
                    return item.clinics.some(clinic => clinic.id === clinicId);
                });
                setDoctors(filterDoctors)
            })
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value);
    };

    function getclinic() {
        setIsLoading(true);
        getClinicDataById({ clinicId })
            .then((res) => {
                setClinicData(res)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link onClick={goBack}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>Book Slot</li>
                    <li>
                        <div align='right'>
                            <MainSelect className='appColor btn_sub' value={selectedValue} onChange={handleChange}>
                                <option value="">Select Assign Doctor</option>
                                {doctors && doctors.map((item, index) => (
                                    <option key={item._id} value={item._id} className="form-control">{item.name}</option>
                                ))}
                            </MainSelect>
                        </div>
                    </li>
                </ul>
            </MainNav>
            <div className='row'>
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box booking">
                        <div>
                            {isLoading === true ?
                                <div className='loader-container'>
                                    <Loader />
                                </div>
                                :
                                <>
                                    <Topbar icon={<FaClinicMedical />}
                                        title={clinicData.clinicName}
                                        slot={<WalkingSlot
                                            clinicId={clinicId}
                                            doctorId={!selectedValue ? doctorId : selectedValue} />}
                                    />

                                    <DoctorAppointmentType
                                        clinicId={clinicId}
                                        doctorId={!selectedValue ? doctorId : selectedValue}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export { AppointmentBookingSection }